import React from "react";

function InternationalSim() {
  return (
    <div class="container">
      {/* <!--banner--> */}
      <div class="banner ">
        <div style={{ padding: "10px" }}></div>
      </div>
      <hr />

      <div style={{ padding: "300px" }}></div>
    </div>
  );
}

export default InternationalSim;
