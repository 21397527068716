import AddNote from './AddNote'
import Notes from './Notes'
// import React from './Notes'
import React from 'react'
const Home1 = () => {
  return (
    <div>
      <div className='container'>
        <Notes/>
      </div>
    </div>
  )
}

export default Home1